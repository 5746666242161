import axios from "axios";
import { IUserConfigurationParameters } from "models/UserConfigurationParameters";

interface IPortalService {
  getUserConfigurationParameters(): Promise<IUserConfigurationParameters>;
  getGatewayUrl(): Promise<string>;
}

export const PortalService: IPortalService = {
  getUserConfigurationParameters: async (): Promise<IUserConfigurationParameters> => {
    const { data } = await axios.get(`/api/configurationParameters`);
    return data;
  },

  getGatewayUrl: async (): Promise<string> => {
    const { data } = await axios.get(`/api/gatewayUrl`);
    return data.gatewayUrl;
  },
};
