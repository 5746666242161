import { FunctionComponent } from "react";

import './ToastNotificationWrapper.scss';

export type ToastNotificationWrapperProps = {
  title: string;
  message: string | JSX.Element;
  error?: Error;
}

const ToastNotificationWrapper: FunctionComponent<ToastNotificationWrapperProps> = (props) => {
  const {title = '', message = ''} = props;
  return (
    <div className="ToastNotificationWrapper">
      <div className="ToastNotificationWrapper__title">{title}</div>
      <div className="ToastNotificationWrapper__message">{message}</div>
    </div>
  );
}

export default ToastNotificationWrapper;