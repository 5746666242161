import React, { createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { notification } from "components/Notification";
import { Resources } from "resources/Resources";
import { useAppState } from "./StateProvider";
import { RoutingService } from "services/RoutingService";
import { MeasurementSystem } from "models/MeasurementSystem";

type DistanceUnit = "mi" | "km";

interface IDistanceConvertionResult {
  value: string;
  unit: DistanceUnit;
}

interface MeasurementSystemContextProps {
  measurementSystem: MeasurementSystem;
  convertMetersBasedOnConfiguration: (meters: number) => IDistanceConvertionResult;
  generalMeasurementSystemUnit: DistanceUnit;
}

const MeasurementSystemContext = createContext<MeasurementSystemContextProps | undefined>(undefined);

export const MeasurementSystemProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { identity } = useAppState();

  const { data } = useQuery(
    ["measurementSystemConfiguration", identity?.mSeriesUserID],
    RoutingService.getMeasurementSystemConfiguration,
    {
      enabled: !!identity?.isAuthenticated,
      onError: (e) => {
        notification.warn({
          title: Resources.get(
            "CommandCenter",
            "getMeasurementSystemConfigurationError",
            "label"
          ),
        });
      },
    }
  );

  const measurementSystem = data || "AmericanSystem";

  const generalMeasurementSystemUnit: DistanceUnit =
    measurementSystem === "MetricSystem" ? "km" : "mi";

  /**
   * Converts a distance from meters to the specified measurement system.
   *
   * @param meters - The distance in meters to convert.
   * @returns An object containing the converted distance and the unit of measurement.
   *          The object has two properties:
   *          - value: The converted distance as a string with two decimal places.
   *          - unit: The unit of the converted distance ("km" for kilometers or "mi" for miles).
   */
  const convertMetersBasedOnConfiguration = (
    meters: number
  ): IDistanceConvertionResult => {
    const value = (generalMeasurementSystemUnit === "km"
      ? meters / 1000
      : meters * 0.00062137
    ).toFixed(2);

    return { value, unit: generalMeasurementSystemUnit };
  };

  return (
    <MeasurementSystemContext.Provider value={{ measurementSystem, generalMeasurementSystemUnit, convertMetersBasedOnConfiguration }}>
      {children}
    </MeasurementSystemContext.Provider>
  );
};

export const useMeasurementSystem = () => {
  const context = useContext(MeasurementSystemContext);
  if (!context) {
    throw new Error(
      "useMeasurementSystem must be used within a MeasurementSystemProvider"
    );
  }
  return context;
};
