import axios from "axios";
import { Endpoints } from "../config/endpoints";
import { EncryptHelper } from "../helpers/EncryptHelper";
import { AuthenticationResult, EncryptedDataResponse } from "../models/AuthenticationResult";
import { HttpInnerResponse } from "../models/HttpInnerResponse";

interface ILoginService {
  getServiceEndpoint(): string;
  loginWithPortal(username: string, password: string): Promise<AuthenticationResult>;
  sendVerificationCode(email: string, retry?: boolean): Promise<EncryptedDataResponse>;
  authenticateSSOUser(ssoCookie: string): Promise<AuthenticationResult>;
}

export const LoginService: ILoginService = {

  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT");
  },

  loginWithPortal: async (username: string, password: string): Promise<AuthenticationResult> => {
    const IdentityServiceEndpoint = LoginService.getServiceEndpoint();
    const payload: string = JSON.stringify({
      login: username,
      password: password
    });

    const message = EncryptHelper.encrypt(payload);

    const { data, status } = await axios.post<HttpInnerResponse>(
      IdentityServiceEndpoint + "security/authenticate",
      { message },
    );

    return {
            statusCode: status,
            // @ts-ignore
            accessToken: data.accessToken, 
            // @ts-ignore
            idToken: {
                id:  data.user?.idUser?.toString(),
                code: data.user?.cdUser,
                login: data.user?.login,
                tenantId: data.user?.tenantId,
                regionId: data.user?.idRegion,
                idRegionPath: data.user?.idRegionPath,
                enabledFeatures: data.user?.accessLevels,
                mSeriesUserID: data.user?.idUser?.toString(),
                nmDisplay : data.user?.nmDisplay
            }
        }  as AuthenticationResult
    },

  sendVerificationCode: async (email: string): Promise<EncryptedDataResponse> => {
    const IdentityServiceEndpoint = LoginService.getServiceEndpoint();
    const endpointUrl = "user/sendCode"
    const { data } = await axios.post( IdentityServiceEndpoint + endpointUrl ,{ email } );
    return data
  },

  authenticateSSOUser: async (ssoCookie: string): Promise<AuthenticationResult> => {
    const IdentityServiceEndpoint = LoginService.getServiceEndpoint();
    const { data }: any = await axios.post(`${IdentityServiceEndpoint}security/authenticateSSOUser/${ssoCookie}`);
    if (!data) return null!;
    return {
      statusCode: 200,
      accessToken: data.payload.user.accessToken,
      idToken: data.payload.user.data,
    } as AuthenticationResult;
  }
};
