import { useEffect, useState } from "react";

export interface usePaginationQueryProps<T> {
    rowsByPage?: number,
    emptyTemplate?: T,
    onLoadRowsAsync: (options: IPaginateOptions<T>) => Promise<T[]>,
    onDataLoaded?: (prevRows: T[], newRows: T[]) => T[],
    customSetRows?: (prevRows: T[], newRows: T[], page?: number) => T[],
    onFinalCallBack?: () => void,
    loadRowsOnInit?: boolean,
}

export interface IPaginateOptions<T> {
    page: number,
    top: number,
    skip: number,
    current: T[]
}

export interface IPaginationCallback {
    name: string,
    hasMore: boolean,
    isLoading: boolean,
    onLoadMore: () => void
}

export const DEFAULT_PAGINATE_ROWS_BY_PAGE = 30;

const usePaginationQuery = <T,>(props: usePaginationQueryProps<T>) => {
    const { loadRowsOnInit = true, rowsByPage = DEFAULT_PAGINATE_ROWS_BY_PAGE, onLoadRowsAsync, onDataLoaded, customSetRows, onFinalCallBack = () => {}} = props;
    const [rows, setRows] = useState<T[]>([]);
    const [loadingCount, setLoadingCount] = useState<number>(0);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [hasInitLoading, setHasInitLoading] = useState<boolean>(loadRowsOnInit);

    useEffect(() => {
        if (hasInitLoading) {
            loadRows();
            setHasMore(true);
        } else {
            setHasInitLoading(true);
        }
    }, [page]);

    const loadRows = async () => {
        setLoadingCount(prev => prev + 1);
        try {
            const result = await onLoadRowsAsync({
                page,
                top: rowsByPage,
                skip: rowsByPage * page,
                current: rows,
            });

            setHasMore((result.length >= rowsByPage));
            if (page == 0) {
                if(customSetRows)
                    setRows(customSetRows(rows, result, page))
                else
                    setRows(result);
            } else if (typeof onDataLoaded === 'function') {
                setRows(onDataLoaded(rows, result));
            } else {
                if(customSetRows)
                    setRows(customSetRows(rows, result, page))
                else
                    setRows(rows.concat(result));
            }
        } catch (error: any) {
            if(error.message === "REQUEST CANCELED"){
                return
            }
            setRows(prev => [...prev]);
        } finally {
            setLoadingCount(prev => prev - 1);
            setDataLoaded(true);
            onFinalCallBack();
        }
    }

    const loadMore = () => {
        if (loadingCount === 0) {
            setPage(page => page + 1);
        }
    }

    const reload = () => {
        if (page !== 0) {
            setPage(0);
        } else {
            loadRows();
        }
    }

    return {
        rows,
        page,
        isLoading: loadingCount > 0,
        dataLoaded,
        hasMore,
        reload,
        loadRows,
        loadMore
    }
}

export default usePaginationQuery;