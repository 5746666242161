import axios, { CancelTokenSource } from "axios";
import { Endpoints } from "../config/endpoints";
import {
  ExtendedCustomerData,
  ICustomer,
  ICustomerFilter,
  ICustomerStatus,
  IExtendedSearchResponse,
  ISearchOptions,
  ISearchResponse,
} from "../models/entities/Customer";

interface ICustomerService {
  getServiceEndpoint(): string;
  getCustomersByRegions(
    regionId: number,
    params?: Partial<ICustomerFilter>
  ): Promise<ICustomer[]>;
  getCustomersById(customerId: string): Promise<ICustomer>;
  getCustomers(params: { idAccount: string | undefined }): Promise<ICustomer[]>;
  getStatus(): Promise<ICustomerStatus[]>;
  getSearch(
    options: ISearchOptions,
    cancelToken?: CancelTokenSource
  ): Promise<IExtendedSearchResponse>;
}

const TIMEOUT_REQUEST = 30000;

// TODO: Update according to real service query params syntax

export const CustomerService: ICustomerService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_CUSTOMER_ENDPOINT");
  },

  getCustomersByRegions: (
    regionId: number,
    params?: Partial<ICustomerFilter>
  ): Promise<ICustomer[]> => {
    const customerServiceUrl = CustomerService.getServiceEndpoint();
    return new Promise(async (resolve, reject) => {
      const response = await axios.get<ICustomer[]>(
        `${customerServiceUrl}regions/${regionId}/customers`,
        { params }
      );
      resolve(response.data as ICustomer[]);
    });
  },
  getCustomersById: (customerId: string): Promise<ICustomer> => {
    const customerServiceUrl = CustomerService.getServiceEndpoint();
    return new Promise(async (resolve, reject) => {
      const response = await axios.get<ICustomer[]>(
        `${customerServiceUrl}customer/${customerId}`
      );
      resolve(response.data?.[0] as ICustomer);
    });
  },
  getCustomers: async (params: {
    idAccount: string | undefined;
  }): Promise<ICustomer[]> => {
    const customerServiceUrl = CustomerService.getServiceEndpoint();
    const { data } = await axios.get<ICustomer[]>(
      `${customerServiceUrl}customers`,
      { params }
    );
    return Promise.resolve(data);
  },
  getStatus: async (): Promise<ICustomerStatus[]> => {
    const customerServiceUrl = CustomerService.getServiceEndpoint();
    const url = `${customerServiceUrl}customers/statuses`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
  getSearch: async (
    options: ISearchOptions,
    cancelToken?: CancelTokenSource
  ): Promise<IExtendedSearchResponse> => {
    const customerServiceUrl = CustomerService.getServiceEndpoint();
    const result = await axios.post<ISearchResponse>(
      `${customerServiceUrl}customers/search`,
      options,
      {
        headers: { "Cache-Control": "min-fresh=36000" },
        timeout: TIMEOUT_REQUEST,
        cancelToken: cancelToken?.token,
      }
    );

    const response: IExtendedSearchResponse = {
      hasNextPage: result.data.hasNextPage,
      offset: result.data.offset,
      limit: result.data.limit,
      rows: result.data.rows,
      params: result.data.params,
      data: result.data.data.map((d) => new ExtendedCustomerData(d)),
    };

    return response;
  },
} as ICustomerService;
