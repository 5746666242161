import { toast, ToastOptions } from 'react-toastify';
import ToastNotificationWrapper, { ToastNotificationWrapperProps } from './ToastNotificationWrapper';

import 'react-toastify/dist/ReactToastify.min.css';

const ERROR_CLOSE_TIMEOUT = 5000; // 5 seconds for errors

const toastInfo = toast.info;
const toastError = toast.error;
const toastWarn = toast.warn;
const toastSuccess = toast.success

const DEFAULT_CONFIG: ToastOptions = {
    position: 'top-right',
    autoClose: ERROR_CLOSE_TIMEOUT,
    transition: undefined,
}

export const notification = Object.assign(toast, {
    info: (content: ToastNotificationWrapperProps, options: ToastOptions = {}) =>
        toastInfo(<ToastNotificationWrapper {...content} />, {
            ...DEFAULT_CONFIG,
            ...options,
        }),
    error: (content: ToastNotificationWrapperProps, options: ToastOptions = {}) =>
        toastError(<ToastNotificationWrapper {...content} />, {
            ...DEFAULT_CONFIG,
            ...options,
        }),
    warn: (content: ToastNotificationWrapperProps, options: ToastOptions = {}) =>
        toastWarn(<ToastNotificationWrapper {...content} />, {
            ...DEFAULT_CONFIG,
            ...options,
        }),
    success: (content: ToastNotificationWrapperProps, options: ToastOptions = {}) =>
        toastSuccess(<ToastNotificationWrapper {...content} />, {
            ...DEFAULT_CONFIG,
            ...options,
        })
});

