import { useState } from "react";
import { CustomerService } from "./../../services/CustomerService";
import { useQuery } from "react-query";
import { ISearchOptions } from "models/entities/Customer";
import { DEFAULT_PAGINATE_ROWS_BY_PAGE } from "hooks/usePaginationQuery";
import { notification } from "components/Notification";
import { Resources } from "resources/Resources";
import axios from "axios";

type UseHeaderSearchParams = {
  page: number;
  minLength: number;
};

export const useHeaderSearch = (params: UseHeaderSearchParams) => {
  const { page, minLength } = params;

  const [querySearch, setQuerySearch] = useState("");

  const search = useHeaderSearchQuery(querySearch, minLength, page);

  return {
    search,
    querySearch,
    setQuerySearch,
  };
};

export const useHeaderSearchQuery = (
  q: string,
  minLength: number,
  page: number = 0
) => {
  const enabled = q.length >= minLength;

  return useQuery(
    ["search", q ?? "", page, minLength],
    async ({ signal }) => {
      try {
        const cancelTokenSource = axios.CancelToken.source();

        signal?.addEventListener("abort", () => {
          cancelTokenSource.cancel("REQUEST CANCELED");
        });

        const searchOptions: ISearchOptions = {
          additiveWhereExclude: "",
          additiveWhereInclude: "",
          restrictiveWhere: "",
          search: q,
          offset: page * DEFAULT_PAGINATE_ROWS_BY_PAGE,
          limit: DEFAULT_PAGINATE_ROWS_BY_PAGE,
          dataIsolation: "1",
          dataIsolationModule: "",
          includeFields: "_id,legacyAccountId,names,addresses",
          sortFields: "names:1,_id:1",
          regionIds: "",
        };

        const data = await CustomerService.getSearch(
          searchOptions,
          cancelTokenSource
        );

        return data;
      } catch (error: any) {
        if (error?.message !== "REQUEST CANCELED") {
          notification.error(
            {
              title: Resources.get(
                "CommandCenter",
                "topSearchBarError",
                "label"
              ),
            },
            { toastId: "Top search bar portal error" }
          );
        }
        throw new Error(error);
      }
    },
    {
      enabled,
    }
  );
};
