import React, { createContext, useContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { notification } from "components/Notification";
import { Resources } from "resources/Resources";
import { useAppState } from "./StateProvider";
import { RoutingService } from "services/RoutingService";

interface FirstWeekDayContextProps {
  teamManagementFirstDay: number;
  timeSheetsFirstDay: number;
}

const FirstWeekDayContext = createContext<FirstWeekDayContextProps | undefined>(undefined);

export const FirstWeekDayProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { identity } = useAppState();

  const { data } = useQuery(
    ["firstWeekDay", identity?.mSeriesUserID],
    RoutingService.getFirstWeekDayConfiguration,
    {
      enabled: !!identity?.isAuthenticated,
      onError: (e) => {
        notification.warn({
          title: Resources.get(
            "CommandCenter",
            "firstDayWeekError",
            "label"
          ),
        });
      },
    }
  );

  return (
    <FirstWeekDayContext.Provider value={{ teamManagementFirstDay: (data?.teamManagementFirstDay || 1) - 1, timeSheetsFirstDay: (data?.timeSheetsFirstDay || 1) - 1 }}>
      {children}
    </FirstWeekDayContext.Provider>
  );
};

export const useFirstWeekDayContext = () => {
  const context = useContext(FirstWeekDayContext);
  if (!context) {
    throw new Error(
      "useFirstWeekDayContext must be used within a FirstWeekDayContextProvider"
    );
  }
  return context;
};