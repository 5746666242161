import { FunctionComponent, useMemo } from "react";

const parser: DOMParser = new DOMParser();

const WRAPPING_TAG = "B";

const HighlightText: FunctionComponent<{ className: string }> = (props) => {
  return <span {...props}>{props.children}</span>;
};

const stripHTML = (html: string) => {
  const htmlDOM = parser.parseFromString(html, "text/html");
  return htmlDOM.body.textContent || "";
};

const renderHighlight = (highlight: string): (string | JSX.Element)[] => {
  const htmlDOM = parser.parseFromString(highlight, "text/html");
  const elements = Array.from(htmlDOM.body.childNodes);

  return elements.map((el, i) => {
    const textContent = el.textContent || "";
    const hasHighlight = el.nodeName === "B";
    return hasHighlight ? (
      <HighlightText key={textContent + String(i)} className="highlight">
        {textContent}
      </HighlightText>
    ) : (
      textContent
    );
  });
};

interface HighlightProps {
  text: string;
  highlight: string;
}

const Highlight: FunctionComponent<
  HighlightProps & JSX.IntrinsicElements["span"]
> = ({ highlight, text, ...restProps }) => {
  // Necessary to avoid unterminated / Invalid regexps
  const cleanHighlight = highlight.replace(/[-\/\\^$*+?.()|[\]{}]/gi, "\\$&");
  const regexp = new RegExp(`${cleanHighlight}`, "gi");
  const safeText = text ?? "";
  const _highlight = safeText.replace(
    regexp,
    (match) => `<${WRAPPING_TAG}>${match}</${WRAPPING_TAG}>`
  );

  const strippedHighlight = useMemo(() => stripHTML(_highlight), [_highlight]);
  const isValidHighlight = strippedHighlight === safeText;

  if (isValidHighlight) {
    return <span {...restProps}>{renderHighlight(_highlight)}</span>;
  }

  const position = safeText.indexOf(strippedHighlight);
  if (position === -1) {
    return <span {...restProps}>{safeText}</span>;
  }

  const modifiedTitle =
    safeText.substring(0, position) +
    _highlight +
    safeText.substring(position + strippedHighlight.length);

  return <span {...restProps}>{renderHighlight(modifiedTitle)}</span>;
};

export default Highlight;
