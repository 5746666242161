import { Endpoints } from "../config/endpoints";
import axios from "axios";
import { IOrder, IOrderCurrency, IOrderStatus } from "../models/Order";
import { IOrderFilter } from "../models/Order/OrderFilter";
import { IOrderListConfig } from "models/Order/OrderListConfig";
import { CustomerService } from "./CustomerService";
import qs from "qs";
import { DateHelper } from "helpers/DateHelper";

interface IOrderService {
  getServiceEndpoint(): string;

  getOrders(params: Partial<IOrderFilter>, callback?: IOrderCallback): Promise<IOrder[]>;

  getOrdersCount(params: Partial<IOrderFilter>): Promise<any>;

  getOrder(orderId: number): Promise<IOrder>;

  getStatus(): Promise<IOrderStatus[]>;

  getOrderListConfig(userId: string): Promise<IOrderListConfig>;

  createOrderListConfig(orderListConfig: IOrderListConfig): Promise<void>;

  updateOrderListConfig(orderListConfig: IOrderListConfig): Promise<void>;

  getPDF(fileName: string): Promise<string>;

  getMultiplePDF(pdfNames: string): Promise<{ id: string, fileData: string }[]>

  getCurrencies(): Promise<IOrderCurrency[]>;
}

export interface IOrderCallback {
  onExtraData(orders: IOrder[]): Promise<IOrder[]>
}

export const OrdersService: IOrderService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_ORDER_ENDPOINT");
  },

  getOrders: async (params: Partial<IOrderFilter>, callback?: IOrderCallback): Promise<IOrder[]> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/list`;
    const { data } = await axios.get(url, {
      params,
      paramsSerializer: params =>
        qs.stringify(params, {
          serializeDate: (date: Date) => DateHelper.format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS")
        })
    });
    if (callback !== undefined) {
      return await callback.onExtraData(data);
    } else {
      return Promise.resolve(data);
    }
  },
  getOrdersCount: async (params: Partial<IOrderFilter>): Promise<IOrder[]> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/count/list`;
    const { data } = await axios.get(url, {
      params: params,
      headers: { 'Cache-Control': 'min-fresh=3600' },
      paramsSerializer: params =>
        qs.stringify(params, {
          serializeDate: (date: Date) => DateHelper.format(date,"yyyy-MM-dd'T'HH:mm:ss.SSS")
        })
    });
    return Promise.resolve(data);
  },
  getOrder: async (orderId: number): Promise<IOrder> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/getOrder/${orderId}`;
    const { data } = await axios.get(url);
    if (data.idAccount) {
      data.customer = (await CustomerService.getCustomers({ idAccount: data.idAccount }))[0];
    }
    return Promise.resolve(data);
  },
  getStatus: async (): Promise<IOrderStatus[]> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/status/list`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
  getCurrencies: async (): Promise<IOrderCurrency[]> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/currency/list`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
  getOrderListConfig: async (userId: string): Promise<IOrderListConfig> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/getListConfig/${userId}`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
  createOrderListConfig: async (orderListConfig: IOrderListConfig): Promise<void> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/saveListConfig/`;
    return axios.post(url, { ...orderListConfig });
  },
  updateOrderListConfig: async (orderListConfig: IOrderListConfig): Promise<void> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/saveListConfig/`;
    return axios.put(url, { ...orderListConfig });
  },
  getPDF: async (pdfName: string): Promise<string> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/getpdf/${pdfName}`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
  getMultiplePDF: async (pdfNames: string): Promise<{ id: string, fileData: string }[]> => {
    const orderServiceUrl = OrdersService.getServiceEndpoint();
    const url = `${orderServiceUrl}order/getMultiplePDF/${pdfNames}`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  }

} as IOrderService;
