import Highlight from "components/Highlight/Highlight";
import { LoadingData } from "components/LoadingData/LoadingData";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Resources } from "resources/Resources";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../Icon/Icon";
import "./InputSearch.scss";
import { Row } from "react-bootstrap";
import {
  IExtendCustomerData,
  IExtendedSearchResponse,
} from "models/entities/Customer";
import { DEFAULT_PAGINATE_ROWS_BY_PAGE } from "hooks/usePaginationQuery";

export type InputSearchEntity = "customer";

export type InputSearchOption = {
  content: string;
  type?: string;
  entity: InputSearchEntity;
  highlight: string;
  relativePath: string | undefined;
  itemId: string;
  document: any;
};

export type InputSearchGroup = {
  label: string;
  options: InputSearchOption[];
  continuationToken?: string;
};

type InputSearchProps = {
  name?: string;
  title?: string;
  className?: string;
  placeholder?: string;
  isLoading: boolean;
  type?: "text" | "number" | "hidden" | "file";
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  optionsGroup: IExtendedSearchResponse | undefined;
  min?: number;
  max?: number;
  focus?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  accept?: string;
  id?: string;
  step?: number;
  pattern?: string;
  onFocus?: (input: React.FocusEvent<any>) => void;
  onChange: (input: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: (value: string) => void;
  onKeyPress?: (input: any) => void;
  onOptionSelect: (option: IExtendCustomerData) => void;
  onShowMore: (page: number) => void;
  errorMessage?: unknown;
  alertMessage?: string;
};

export const InputSearch: React.FC<InputSearchProps> = (props, _ref) => {
  const ref = useRef(null);
  const textInput = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const {
    focus,
    className,
    value = "",
    isLoading,
    optionsGroup,
    onClear,
    onChange,
    onOptionSelect,
    onShowMore,
    errorMessage,
    alertMessage,
    ...restProps
  } = props;

  const [_value, setValue] = useState<string>(value);
  const [_focus, setFocus] = useState<boolean>(!!focus);
  const [expanded, setExpanded] = useState(false);

  const [recentOptions, setRecentOptions] = useState<IExtendCustomerData[]>([]);
  const [lastLocation, setLastLocation] = useState<string>("");
  const [pageChanged, setPageChanged] = useState<boolean>(false);

  useEffect(() => {
    if (lastLocation != location.pathname) {
      setLastLocation(location.pathname);
      setPageChanged(true);
      setValue("");
      onClear("");
    }
  }, [location]);

  useEffect(() => {
    if (_focus) {
      textInput?.current?.focus();
      setPageChanged(false);
    }
  }, [_focus]);

  const isOptionsVisible = !!optionsGroup?.data && optionsGroup.data.length > 0;

  const isRecentOptionsVisible = useMemo<boolean>(
    () => !_value && _focus && recentOptions.length > 0,
    [_focus, _value, recentOptions]
  );

  const handleClear = () => {
    setValue("");
    onClear("");
    setTimeout(() => {
      setFocus(true);
    }, 100);
    setTimeout(() => {
      setExpanded(true);
    }, 200);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
    onChange(evt);
  };

  const handleFocus = (_: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    setExpanded(true);
  };

  const handleBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    // This exists to avoid hiding options before option click is triggered due to onBlur executing before onClick
    setTimeout(() => {
      setFocus(false);
    }, 500);
  };

  const handleOptionSelect = (opt: IExtendCustomerData) => {
    const _recents = uniqBy(
      [{ ...opt, highlight: _value }, ...recentOptions],
      ({ ...rest }) => JSON.stringify(rest)
    );
    setRecentOptions(_recents);
    onOptionSelect(opt);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !(ref?.current as any).contains(event.target as Element)
      ) {
        setExpanded(false);
      }
    };
    const removeWindowEventListener = () =>
      document.removeEventListener("mousedown", handleClickOutside);
    const addWindowEventListener = () =>
      document.addEventListener("mousedown", handleClickOutside);

    (expanded ? addWindowEventListener : removeWindowEventListener)();
    return () => removeWindowEventListener();
  }, [ref, expanded]);

  const handleClearRecent = () => {
    setRecentOptions([]);
    setTimeout(() => setFocus(true), 100);
  };

  const page = (optionsGroup?.offset ?? 0) / DEFAULT_PAGINATE_ROWS_BY_PAGE;

  return (
    <div ref={ref} className={`InputSearch ${className}`}>
      <div className={`InputSearch__input-container`}>
        <input
          ref={textInput}
          {...{ ...restProps }}
          autoComplete="off"
          value={_value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Icon className="InputSearch__icon" name="search-v2" />
        {_value && (
          <Icon
            name="close"
            className="InputSearch__clear"
            onClick={handleClear}
          />
        )}
      </div>

      {/* No results message */}
      {!alertMessage && ((!isOptionsVisible && _value) || errorMessage) ? (
        <div className="InputSearch__options">
          <div className="InputSearch__options-container">
            <div className="InputSearch__options-group">
              <label className="InputSearch__option-group-label">
                {errorMessage
                  ? Resources.get(
                      "CommandCenter",
                      "searchBarUnavailable",
                      "label"
                    )
                  : Resources.get("InputSearch", "noResults", "label")}
              </label>
            </div>
          </div>
        </div>
      ) : null}

      {/* alert message */}
      {alertMessage && (
        <div className="InputSearch__options">
          <div className="InputSearch__options-container">
            <div className="InputSearch__options-group">
              <label className="InputSearch__option-group-label">
                {alertMessage}
              </label>
            </div>
          </div>
        </div>
      )}

      {/* Recent Options */}
      {isRecentOptionsVisible && (
        <div className="InputSearch__options">
          {recentOptions && recentOptions?.length > 0 && (
            <div className="InputSearch__options-container">
              <div
                key={`InputSearch__options-group--recent}`}
                className="InputSearch__options-group"
              >
                <label className="InputSearch__option-group-label">
                  <span>
                    {Resources.get("InputSearch", "recents", "label")}
                  </span>
                  <span
                    className="InputSearch__option-group-clear"
                    onClick={handleClearRecent}
                  >
                    {Resources.get("InputSearch", "clearRecent", "label")}
                  </span>
                </label>
                {recentOptions.map((option) => (
                  <div
                    key={`InputSearch__options--${uuidv4()}-${option._id}}`}
                    className="InputSearch__option"
                    onClick={() => {
                      setFocus(false);
                      handleOptionSelect(option);
                    }}
                  >
                    <Row>
                      <Highlight
                        text={`${option.names.default} - ${option._id}`}
                        highlight={_value}
                        className="InputSearch__option-detail-name"
                      />
                    </Row>
                    <Row style={{ marginBottom: "-6px" }}>
                      <Highlight
                        text={option.defaultAddressDescription ?? ""}
                        highlight={_value}
                        className="InputSearch__option-detail-address"
                      />
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Search Options */}
      {expanded && isOptionsVisible && (
        <div className="InputSearch__options">
          <div className="InputSearch__options-container">
            {!!optionsGroup && (
              <>
                {
                  <div
                    key={`InputSearch__options-group--${optionsGroup.offset}`}
                    className="InputSearch__options-group"
                  >
                    {
                      <label className="InputSearch__option-group-label">
                        {`${Resources.get(
                          "CommandCenter",
                          "topSearchBarResult",
                          "label"
                        )}: ${page + 1}`}
                      </label>
                    }
                    {optionsGroup.data.map((option) => (
                      <div
                        id={`InputSearch__options--${uuidv4()}-${option._id}}`}
                        key={`InputSearch__options--${uuidv4()}-${option._id}}`}
                        className="InputSearch__option"
                        onClick={() => {
                          setExpanded(false);
                          handleOptionSelect(option);
                        }}
                      >
                        <Row>
                          <Highlight
                            text={`${option.names.default} - ${option._id}`}
                            highlight={_value}
                            className="InputSearch__option-detail-name"
                          />
                        </Row>
                        <Row style={{ marginBottom: "-6px" }}>
                          <Highlight
                            text={option.defaultAddressDescription ?? ""}
                            highlight={_value}
                            className="InputSearch__option-detail-address"
                          />
                        </Row>
                      </div>
                    ))}
                    {optionsGroup.hasNextPage && (
                      <div
                        className="InputSearch__options-group InputSearch__more"
                        onClick={() => {
                          onShowMore(page + 1);
                          setFocus(true);
                          setExpanded(true);
                        }}
                      >
                        <div className="InputSearch__more-message">
                          <Icon
                            className="InputSearch__more-icon"
                            name="search-v2"
                          />
                          <div>
                            <div>
                              <span>
                                {Resources.get(
                                  "InputSearch",
                                  "showMoreFor",
                                  "label"
                                )}{" "}
                                <Highlight text={_value} highlight={_value} />
                              </span>
                            </div>
                            <div>
                              <span className="InputSearch__more-cta">
                                {Resources.get(
                                  "InputSearch",
                                  "showMoreCTA",
                                  "label"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                }
              </>
            )}
          </div>
        </div>
      )}

      {!pageChanged && isLoading && !isOptionsVisible && (
        <div className="InputSearch__options">
          <LoadingData
            className="ActionButton__spinner"
            data-testid="loadingSpinner"
            animation="border"
          />
        </div>
      )}
    </div>
  );
};
