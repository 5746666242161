import { InputSearch } from "components/InputSearch/InputSearch";
import { useAppState } from "context/StateProvider";
import { useHeaderSearch } from "hooks/CommandCenter/useHeaderSearch";

import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as SpringGlobalTextLogo } from "./../../assets/icons/svg/spring-global-text-logo.svg";
import { useDebouncedCallback } from "use-debounce";
import "./HeaderV2.scss";
import { Resources } from "../../resources/Resources";
import { Icon } from "components/Icon/Icon";
import { BetaTest } from "components/BetaTest/BetaTest";
import { UsersService } from "services/UsersService";
import { Skeleton } from "@mui/material";
import { IExtendCustomerData } from "models/entities/Customer";

const DELAY_TYPE_SEARCH = 500;

interface HeaderV2UserMenuProps {
  updateBetaOption: (param?: any) => any;
}

const HeaderV2UserMenu: FunctionComponent<HeaderV2UserMenuProps> = (props) => {
  const { updateBetaOption } = props;
  const { identity } = useAppState();

  const userInitials = useMemo(() => {
    const names = (identity?.nmDisplay || identity?.code || "??")
      .trim()
      .split(" ");
    const first = names[0][0];
    const last = names.length > 1 ? names[names.length - 1][0] : names[0][1];
    return `${first}${last}`.toUpperCase();
  }, [identity]);

  const [betaFeatureVisible, setBetaFeatureVisible] = useState<boolean>(false);
  const ref = useRef(null);

  const [loadingBeta, setLoadingBeta] = useState<boolean>(true);
  const [betaPreferences, setBetaPreferences] = useState<Object>({});

  useEffect(() => {
    if (identity?.id)
      UsersService.getUserPreferences().then((result: any) => {
        setLoadingBeta(false);
        setBetaPreferences(result?.beta || {});
      });
  }, [identity]);

  const handleShowBetaModal = () => {
    if (loadingBeta === false) setBetaFeatureVisible(!betaFeatureVisible);
  };

  return (
    <>
      <div className="HeaderV2UserMenu">
        <div
          ref={ref}
          className={`HeaderV2UserMenu__beta ${
            betaFeatureVisible ? "betaVisible" : "betaInvisible"
          }`}
          onClick={handleShowBetaModal}
        >
          {loadingBeta == true ? (
            <Skeleton variant="circular" width={40} height={40}></Skeleton>
          ) : (
            <Icon name="construction" />
          )}
        </div>
        <div className="HeaderV2UserMenu__icon">
          <span className="HeaderV2UserMenu__icon-initials">
            {userInitials}
          </span>
        </div>
      </div>
      <BetaTest
        modalVisible={betaFeatureVisible}
        alertVisible={false}
        onBlur={() => {
          setBetaFeatureVisible(false);
        }}
        iconRef={ref}
        onUpdateBetaOption={updateBetaOption}
        loading={loadingBeta}
        betaPreferences={betaPreferences}
      />
    </>
  );
};

interface HeaderV2AutocompleteSearchProps {}

const HeaderV2AutocompleteSearch: FunctionComponent<HeaderV2AutocompleteSearchProps> = (
  props
) => {
  const history = useHistory();

  const [page, setPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");

  const {
    search: searchResult,
    setQuerySearch,
    querySearch,
  } = useHeaderSearch({ minLength: 3, page });
  const { data: rawData, isLoading: isLoadingUserSearch, error } = searchResult;

  const [alertMessage, setAlertMessage] = useState<string>();

  const debouncedSearch = useDebouncedCallback((codeSearch: string) => {
    setPage(0);
    setQuerySearch(codeSearch.replace(/\s+/g, " "));
  }, DELAY_TYPE_SEARCH);

  const handleSearchTextchange = ({
    nativeEvent,
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (value.length > 0 && value.length < 3) {
      setAlertMessage("Please enter a minimum of 3 characters to search");
    } else if (value.length > 2) {
      debouncedSearch(value);
      setSearchText(value);
      setAlertMessage(undefined);
      setPage(0);
    } else {
      handleSearchTextClear(value);
    }
  };

  const handleSearchTextClear = (value: string) => {
    setSearchText(value);
    setQuerySearch("");
    debouncedSearch.cancel();
    setAlertMessage(undefined);
  };

  const handleOptionSelect = (option: IExtendCustomerData) => {
    history.push({
      pathname: `/View360/${option.legacyAccountId}`,
      state: { itemId: option.legacyAccountId },
    });
  };

  const handleShowMore = (page: number) => {
    setPage(page);
  };

  return (
    <div className="HeaderV2AutocompleteSearch">
      <InputSearch
        className="HeaderV2AutocompleteSearch__input"
        name="filter"
        placeholder={Resources.get("CommandCenter", "searchBar", "placeholder")}
        value={searchText}
        optionsGroup={rawData}
        isLoading={isLoadingUserSearch}
        onChange={handleSearchTextchange}
        onClear={handleSearchTextClear}
        onOptionSelect={handleOptionSelect}
        onShowMore={handleShowMore}
        disabled={false}
        errorMessage={error}
        alertMessage={alertMessage}
      />
    </div>
  );
};

interface HeaderV2Props {
  middleComponents?: React.ReactChildren;
  rightComponents?: React.ReactChildren;
  className?: string;
  updateBetaOption: (param?: any) => any;
}

const HeaderV2: FunctionComponent<HeaderV2Props> = (props) => {
  const {
    middleComponents,
    rightComponents,
    className,
    updateBetaOption,
  } = props;

  const _middleComponents = useMemo(
    () => middleComponents || React.createElement(HeaderV2AutocompleteSearch),
    [middleComponents]
  );
  const _rightComponent = useMemo(
    () =>
      rightComponents ||
      React.createElement(HeaderV2UserMenu, { updateBetaOption }),
    [rightComponents]
  );
  const history = useHistory();

  const handleLogoAction = () => {
    history.push({ pathname: "/" });
  };

  return (
    <div className={`HeaderV2 ${className}`}>
      <SpringGlobalTextLogo
        onClick={handleLogoAction}
        className=" HeaderV2__left-section HeaderV2__spring-logo"
      />
      <div className="HeaderV2__middle-section">{_middleComponents}</div>
      <div className="HeaderV2__right-section">{_rightComponent}</div>
    </div>
  );
};

export default HeaderV2;
